<template>
  <div>
    <div class="maxtabbox" >
      <div class="tabbox">
        
        <div class="listbox flex ">
          <div
            :class="activeindex == index ? 'itembox activeclass' : 'itembox'"
            @click="itemclick(index,item.id)"
            v-for="(item, index) in tabArr"
            :key="index"
          >
           {{ item.en_name }} {{ item.name}}
          </div>
        </div>
      </div>
    </div>
    <!-- 背景图 -->
    <div class="topimgbox">
      <img style="width: 100%;height: 100%;" :src="banner" alt="">
    </div>
    <!-- 循环 -->
    <div class="imgmaxbox" v-if="listarr.length!=0">
      <div class="listbox">
        <div class="itembox" v-for="(item, index) in listarr" :key="index" @click="itemimgclick(item.id)">
          <div class="Widegraph" v-if="indexarr[index%8]===1">
            <!-- 长图 -->
            <img
            :src="item.wide_image"
            alt=""
          />
          <div class="kuanzz"></div>
          <div class="textbox">
            <div class="toptet">{{ item.style }}</div>
            <div class="flex">
              <div class="contet">{{ item.name }}</div>
              <img v-if="item.is_hot==1" src="@/assets/page/huo.png" alt="">
            </div>
          </div>
          </div>
          <div class="Shortgraph" v-else>
            <!-- 短图 -->
            <img :src="item.image" alt="">
            <div class="kuanzz"></div>
            <div class="textbox">
              <div class="toptet">{{ item.style }}</div>
              <div class="flex">
                <div class="contet">{{ item.name }}</div>
                <img v-if="item.is_hot==1" src="@/assets/page/huo.png" alt="">
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
   <!-- 缺失页 -->
   <div class="maxqsbox" v-else>
      <div class="qsbox">
        <img src="@/assets/page/qst.png" alt="">
        <div>暂无数据</div>
      </div>
    </div>
    
    <div style="margin: 40px auto;" class="fnebox">
      <el-pagination
          background
          class="fenye"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          layout="prev, pager, next"
          :page-size="page.per_page"
          :total="page.total"
        >
       </el-pagination>
    </div>
   
  </div>
</template>

<script>
import {homedata,homeSeries} from '../../service/request/homepage'
export default {
  data() {
    return {
      activeindex: 0, //高亮
      indexarr:[1,2,2,2,2,1,1,1],//判断索引，1代表长2代表短
      tabArr: [],//tab
      banner: '',//背景图
      listarr:[],
      id: '',//页面id
      page: {
        page:1,
        last_page: 0,
        per_page: 8,
        total: 1,
      },
    }
  },
  components: {},
  mounted() {
    this.activeindex = this.$route.query.index
    this.id =Number( this.$route.query.id)
    this.initTar()//tab
    this.homeSeries()//列表
  },
  methods: {
    // tab数据
    initTar() {
      homedata().then(res => {
        console.log('tar', res);
        this.tabArr = res.msg.list
        this.banner=res.msg.banner
      })
    },
    // 列表初始数据
    homeSeries() {
      homeSeries(
        {
          id: this.id,
          page: this.page.page,
        }
      ).then(res => {
        console.log(res, 'list');
        this.page.last_page = res.msg.last_page
        this.page.per_page = res.msg.per_page
        this.page.total = res.msg.total
        this.listarr=res.msg.data
        // console.log(this.page);
      })
    },
    // 高亮,item列表点击
    itemclick(index,id) {
      this.activeindex = index
      this.id = Number(id)
      this.page.page=1
      this.homeSeries()
    },
    //分页
    handleCurrentChange(val) {
      console.log(11);
      this.page.page = val;
      this.homeSeries()
      window.scrollTo(0, 0)
    },
    // 跳转
    itemimgclick(id) {
      this.$router.push({
        path: '/furnitureinfo',
        query: {
          id
        }
      })
    }
  }
}
</script>
<style scoped lang="less">

.maxtabbox{
  height: 208px;
  width: 100%;
  top: -2px;
  left: 0;
  z-index: 9;
  background-color: #fff;
  position: fixed;

}
.tabbox {
  padding-top: 143px;
  margin: 0 85px;
  position: relative;
  
  .listbox {
    .itembox {
      color: #999999;
      font-size: 24px;
      margin-right: 67px;
      padding-bottom: 2px;
      border-bottom: 1px solid #fff;
    }
    .activeclass {
      color: #000;
      border-bottom: 3px solid #000;
    }
  }
}
.topimgbox {
  width: 100%;
  height: 610px;
  margin-top: 220px;
  // background: url('../../assets/page/z2.png') no-repeat;
  // background-size: cover;
}
.imgmaxbox {
  margin: 0 70px;
  .listbox {
    display: flex;
    flex-wrap: wrap;
    
    /* // 宽图 */
    .itembox {
      transition: 0.8s all;
      margin-top: 15px;
      margin-right: 15px;
      cursor: pointer;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
       .Widegraph{
        width: 868px;
        height: 578px;
        position: relative;
        transition: 0.8s all;
        .kuanzz{
          position: absolute;
          top: 0;
          
          left: 0;
          opacity: 0;
          transition: 0.8s all;
          width: 868px;
          height: 578px;
          background-color: rgba(0, 0, 0, 0.2);
        }
          img {
          width: 868px;
          height: 578px;
        }

       }
       .Shortgraph{
        width: 426px;
        height: 578px;
        position: relative;
        .kuanzz{
          position: absolute;
          top: 0;
          
          left: 0;
          opacity: 0;
          transition: 0.8s all;
          width: 426px;
        height: 578px;
          background-color: rgba(0, 0, 0, 0.2);
        }
        img{
          width: 426px;
          height: 578px;
          transition: 0.6s all;
        }
       }
       .textbox{
        position: absolute;
        bottom: 40px;
        left: 40px;
        .toptet{
          font-size: 16px;
          color: #fff;
          font-weight: 500;
        }
        .contet{
          font-size: 25px;
          color: #FFFFFF;
          font-weight: 500;
          margin-right: 10px;
        }
        img{
          width: 25px;
          height: 28px;
        }
       }
    }
    .itembox:hover img{
      transition: 0.8s all;
      transform: scale(1.2);
      /* transform: translateY(-10px); */
    }
    .itembox:hover .kuanzz{
      transition: 0.8s all;
      opacity: 1;
    }
    
  }
  .itembox:nth-child(3n){
      margin-right: 0;
    }
    .itembox:nth-child(8){
      margin-right: 0;
    }
}
.fnebox{
  position: relative;
  .el-pagination{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
  }
}
.maxqsbox{
  width: 100%;
  display: flex;
  justify-content: center;
  .qsbox{
   margin: 140px auto 0;
   min-height: 500px;
   text-align: center;
  img{
    width: 112px;
    height: 112px;
  }
  div{
    margin-top: 10px;
    color: #999;
  }

}
}
</style>
